/* eslint-disable no-underscore-dangle */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { ActionMeta, components, OptionProps } from 'react-select';
import {
  InputContainer,
  LimitIndication,
  SelectboxContainer,
  StyledCheckbox,
  StyledError,
  StyledOption,
  StyledSelect,
} from 'components/shared/selectbox/Selectbox.style';
import { StyledLabel } from 'components/shared/textField/TextField.style';
import { Controller, ControllerRenderProps, FieldValues } from 'react-hook-form';
import Chip from 'components/shared/chip/Chip';
import { convertIdsArrayToGenericItemsArray } from 'utils/mapping';
import { defaultTheme } from 'styles/themes/defaultTheme';
import useOnClickOutside from 'hooks/use-onclick-outside';
import zIndex from 'styles/zIndex';
import { getIsControlWithError } from 'utils/form';
import { get, set } from 'lodash';
import { SelectboxProps } from './Selectbox.consts';

export const Selectbox = ({
  items,
  initialSelectedItems,
  label,
  placeholder = 'Select',
  name,
  valueField = 'id',
  labelField = 'name',
  disabled,
  clearOnDisabled,
  multiple,
  withSearch = false,
  labelIsHorizontal,
  allowSelectAll,
  allSelected,
  withAmount,
  reset,
  defaultValue,
  control,
  validation,
  errors,
  limit,
  maxItems,
  onChange,
  onCreateOption,
  selectWidth,
  className,
  theme = defaultTheme,
  getIsOptionDisabled,
  customOption,
}: SelectboxProps) => {
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef();
  useOnClickOutside(ref, () => setIsOpen(false));
  const memoizedValidation = useMemo(() => validation, [validation]);
  const allOption = {
    [valueField]: '*',
    [labelField]: 'All',
  };
  const [isLoading, setIsLoading] = useState(false);
  const [options, setOptions] = useState(items);

  const getRegularValue = () =>
    allowSelectAll && allSelected
      ? [allOption, ...items]
      : initialSelectedItems && items
      ? convertIdsArrayToGenericItemsArray(initialSelectedItems, items, valueField)
      : defaultValue;

  const getControlledValue = (fieldValue: any): any[] => {
    if (!fieldValue) {
      return getRegularValue();
    }
    return items
      ? multiple
        ? convertIdsArrayToGenericItemsArray(
            fieldValue.map((i: any) => (typeof i === 'object' ? i[valueField] : i)),
            items,
            valueField,
          )
        : items?.find((option) => option[valueField] === fieldValue)
      : defaultValue;
  };

  const [value, setValue] = useState(
    control ? getControlledValue(get(control._defaultValues, name)) : getRegularValue(),
  );

  const Option = (props: OptionProps<any> & { value: any }) => {
    const { value: optionValue, label: optionLabel, isSelected } = props;
    const isSelectAllIntermediate =
      multiple &&
      allowSelectAll &&
      optionValue?.value === allOption[valueField] &&
      value?.length > 0 &&
      value?.length < options.length;
    return (
      <div
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {multiple && optionValue ? (
          <components.Option {...props}>
            <StyledOption title={optionLabel}>
              <StyledCheckbox onClick={null} checked={isSelected} intermediate={isSelectAllIntermediate} />
              <label>{optionLabel}</label>
            </StyledOption>
          </components.Option>
        ) : (
          <components.Option {...props} />
        )}
      </div>
    );
  };

  const MultiValue = ({ index, getValue, ...props }: any) => {
    const overflowItems = maxItems
      ? getValue()
          .slice(maxItems)
          .map((x: any) => x[labelField])
      : null;

    return !maxItems || index < maxItems ? (
      <Chip
        title={props.data[labelField]}
        {...{
          onClose: !disabled
            ? (e) => {
                e.stopPropagation();
                props.removeProps.onClick();
              }
            : null,
        }}
      >
        {props.data[labelField]}
      </Chip>
    ) : index === maxItems ? (
      <Chip extra onClose={null} title={overflowItems.join(', ')}>{`+${overflowItems.length}`}</Chip>
    ) : null;
  };

  const ValueContainer = ({ children, ...props }: any) => {
    const currentValues = props.getValue();
    let toBeRendered = children;
    if (allowSelectAll && currentValues.some((val: any) => val[valueField] === allOption[valueField])) {
      toBeRendered = [[children[0][0]], children[1]];
    }

    return (
      <components.ValueContainer {...props}>
        {toBeRendered} {limit ? <LimitIndication>{`${value?.length || 0} of ${limit}`}</LimitIndication> : null}
      </components.ValueContainer>
    );
  };

  const handleChange = (newValue: any, event: any) => {
    if (allowSelectAll && multiple) {
      if (
        (event.action === 'remove-value' && event.removedValue[valueField] === allOption[valueField]) ||
        (event.action === 'deselect-option' && event.option[valueField] === allOption[valueField])
      ) {
        setValue([]);
        if (onChange) {
          onChange([]);
        }
        return;
      }
      if (newValue?.length > 0) {
        if (newValue[newValue.length - 1][valueField] === allOption[valueField]) {
          setValue([allOption, ...options]);
          if (onChange) {
            onChange(options);
          }
          return;
        }
        let result = [];
        if (newValue.length === options.length) {
          result = newValue.filter((option: any) => option[valueField] !== allOption[valueField]);
          if (event.action === 'select-option') {
            result = [allOption, ...options];
          }
          setValue(result);
          if (onChange) {
            onChange(options);
          }
          return;
        }
      }
    }

    setValue(newValue);
    if (onChange) {
      onChange(newValue);
    }
    if (!multiple) {
      setIsOpen(false);
    }
    if (!newValue || newValue?.length === 0) {
      setTimeout(() => (document.activeElement as HTMLElement).blur(), 100);
    }
    if (name === 'localSchedule.period' && event.action === 'clear') {
      set(control, '_defaultValues.' + name, newValue);
    }
  };

  const handleCreate = async (inputValue: string, field: ControllerRenderProps<FieldValues, string>) => {
    setIsLoading(true);
    setIsOpen(false);
    const newOption = await onCreateOption(inputValue);
    if (newOption) {
      setOptions([newOption, ...options]);
      setValue([newOption, ...(value || [])]);
    }
    setIsLoading(false);
    if (field) {
      field.onChange([newOption, ...(value || [])].map((v: any) => v.id));
    }
  };

  const getOptionName = (option: any) =>
    withAmount && option.amount ? `${option[labelField]} (${option.amount})` : option[labelField];

  const getOptionLabel = (option: any) => {
    if (onCreateOption) {
      return option.__isNew__ ? option.label : getOptionName(option);
    }
    return getOptionName(option);
  };

  const hasError = getIsControlWithError(name, errors);

  const getSingleValueStyle = (provided: any) => ({
    ...provided,
    color: name === 'isPriority' && disabled === true ? theme.colors.text.disabled : theme.colors.text.primary,
  });

  const customStyles = useMemo(
    () => ({
      valueContainer: (provided: any) => ({
        ...provided,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        flexWrap: 'nowrap',
        padding: '0',
        display: 'flex',
        fontSize: '13px',
        position: 'relative',
      }),
      singleValue: (provided: any) => getSingleValueStyle(provided),
      control: (provided: any) => ({
        ...provided,
        border: 0,
        borderRadius: 0,
        boxShadow: 'none',
        borderBottom: `1px solid ${hasError ? theme.colors.global.error : theme.colors.global.border}`,
        backgroundColor: theme.colors.global.background,
        minHeight: '28px',
        ':hover': {
          ...provided[':hover'],
          borderColor: theme.colors.text.disabled,
          borderBottom: `1px solid ${hasError ? theme.colors.global.error : theme.colors.text.disabled}`,
        },
      }),
      dropdownIndicator: (provided: any) => ({
        ...provided,
        padding: '0',
        transform: 'scale(0.8)',
        cursor: 'pointer',
        color: theme.colors.global.border,
        ':hover': {
          ...provided[':hover'],
          color: theme.colors.text.disabled,
        },
      }),
      clearIndicator: (provided: any) => ({
        ...provided,
        padding: '0',
        transform: 'scale(0.8)',
        cursor: 'pointer',
        color: theme.colors.global.border,
        ':hover': {
          ...provided[':hover'],
          color: theme.colors.text.disabled,
        },
      }),
      option: (provided: any, { isDisabled }: any) => ({
        ...provided,
        backgroundColor: 'none',
        color: isDisabled ? theme.colors.text.disabled : theme.colors.text.primary,
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        overflow: 'hidden',
        ':hover': {
          ...provided[':hover'],
          backgroundColor: !isDisabled ? theme.colors.global.selectedBackground : undefined,
        },
      }),
      placeholder: (provided: any) => ({
        ...provided,
        fontWeight: '300',
        position: 'absolute',
      }),
      menu: (provided: any) => ({
        ...provided,
        border: `1px solid ${theme.colors.global.border}`,
        boxShadow: '1px 1px 10px 0 rgb(0 0 0 / 22%)',
        zIndex: zIndex.dropdown,
        width: 'auto',
        minWidth: '100%',
        maxWidth: '500px',
      }),
      menuList: (provided: any) => ({
        ...provided,
        maxHeight: '190px',
      }),
      container: (base: any) => ({ ...base, width: selectWidth }),
    }),
    [hasError, disabled],
  );

  useEffect(() => {
    if (initialSelectedItems && !control) {
      setValue(getRegularValue());
    }
  }, [initialSelectedItems]);

  useEffect(() => {
    const fieldValue = get(control?._defaultValues, name);
    if (fieldValue) {
      setValue(getControlledValue(fieldValue));
    }
  }, [get(control?._defaultValues, name)]);

  useEffect(() => {
    setOptions(items);
  }, [items]);

  useEffect(() => {
    if (disabled && clearOnDisabled) {
      setValue(null);
    }
  }, [disabled]);

  return (
    <SelectboxContainer
      labelIsHorizontal={labelIsHorizontal}
      className={className}
      data-automation-id={`selectbox-${name}`}
      ref={ref}
      onClick={() => {
        if (!disabled && !isLoading) {
          setIsOpen(!isOpen);
        }
      }}
      tabIndex={0}
    >
      {label && (
        <StyledLabel theme={theme} disabled={disabled} labelIsHorizontal={labelIsHorizontal}>{`${label}${
          validation?.required ? '*' : ''
        }`}</StyledLabel>
      )}
      <InputContainer>
        {control ? (
          <Controller
            control={control}
            name={name}
            rules={memoizedValidation}
            defaultValue={defaultValue}
            render={({ field }) => (
              <StyledSelect
                getOptionLabel={getOptionLabel}
                getOptionValue={(option: any) => option[valueField]}
                options={multiple && allowSelectAll ? [allOption, ...options] : options}
                name={name}
                placeholder={placeholder}
                isDisabled={disabled || isLoading}
                isSearchable={withSearch}
                closeMenuOnSelect={false}
                hideSelectedOptions={false}
                isMulti={multiple}
                isClearable={reset}
                onChange={(newValue: any, event: ActionMeta<any>) => {
                  if (newValue) {
                    field.onChange(multiple ? newValue.map((item: any) => item[valueField]) : newValue[valueField]);
                  } else if (field.name === 'localSchedule.period' && !newValue) {
                    field.onChange(newValue);
                  }
                  handleChange(newValue, event);
                }}
                defaultValue={defaultValue}
                value={value}
                {...(onCreateOption && {
                  isLoading,
                  onCreateOption: (val: any) => handleCreate(val, field),
                })}
                isOptionDisabled={(option: any) =>
                  (limit && value?.length >= limit && !value.includes(option)) || getIsOptionDisabled?.(option)
                }
                components={{ Option, MultiValue, ValueContainer, IndicatorSeparator: (): any => null }}
                styles={customStyles}
                width={selectWidth}
                menuIsOpen={isOpen}
              />
            )}
          />
        ) : (
          <StyledSelect
            getOptionLabel={getOptionLabel}
            getOptionValue={(option: any) => option[valueField]}
            options={multiple && allowSelectAll ? [allOption, ...options] : options}
            name={name}
            placeholder={placeholder}
            isDisabled={disabled || isLoading}
            isSearchable={withSearch}
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            isMulti={multiple}
            isClearable={reset}
            onChange={handleChange}
            defaultValue={defaultValue}
            value={value}
            {...(onCreateOption && { isLoading, onCreateOption: handleCreate })}
            isOptionDisabled={(option: any) =>
              (limit && value?.length >= limit && !value.includes(option)) || getIsOptionDisabled?.(option)
            }
            components={name === 'isPriority' ? customOption : { Option, MultiValue, ValueContainer, IndicatorSeparator: (): any => null }}
            styles={customStyles}
            width={selectWidth}
            menuIsOpen={isOpen}
          />
        )}
        <StyledError name={name} errors={errors} />
      </InputContainer>
    </SelectboxContainer>
  );
};
