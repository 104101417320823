import React from 'react';
import { GridCellParams, GridValueFormatterParams, GridValueGetterParams } from '@mui/x-data-grid-pro';
import { TableProps } from 'components/shared/table/Table.consts';
import { formatDate } from 'utils/date';
import StatusLabel from 'components/shared/statusLabel/StatusLabel';
import { ApprovalStatus, CampaignApprovalStatusLabel, DateTimeConfig, PriorityLevelLabels, PriorityLevels } from 'utils/types';
import { CampaignAlert, CampaignAlertLabel, CampaignType, CampaignTypeLabel } from 'utils/types/campaigns';
import ActionsCell from 'components/shared/actionsCell/ActionsCell';
import { ActionType } from 'components/shared/actionsCell/ActionsCell.consts';
import { RoleGuard } from 'components/roleGuard/RoleGuard';
import { UserRole } from 'utils/types/users';
import { isInArray } from 'utils/array';
import Tooltip from 'components/shared/tooltip/Tooltip';
import CellTitleWrapper from 'components/shared/table/components/cellTitleWrapper/CellTitleWrapper';
import { ButtonText } from 'components/shared/button';
import {
  AlertIconContainer,
  StyledAlertIcon,
  StyledLabelIcon,
  StyledStatusLabel,
  TitleWrapper,
} from 'pages/campaigns/campaignManagement/Campaigns.style';
import { AvailableStatuses } from 'pages/reports/redemptions/components/redemptionsFilterBar/components/campaignSelection/CampaignSelection.consts';
import Checkbox from 'components/shared/checkbox/Checkbox';
import { CampaignProps } from 'pages/campaigns/campaignManagement/Campaigns.consts';
import NewLockIcon from 'pages/shared/lockIcon/NewLockIcon';

export const getCampaignsTableProps = (
  onActionClick: (actionType: ActionType, campaign: CampaignProps) => void,
  dateTimeConfig: DateTimeConfig,
  drillDownToRedemptionsReport: (externalId: number) => void,
  selectionMode: {
    getIsChecked: (campaignId: number) => boolean;
    getSelectAllState: () => { checked: boolean; intermediate: boolean };
    onCampaignSelection: (campaignId: number) => void;
    onSelectAll: () => void;
  },
  isPriorityList: boolean,
): TableProps => {
  return {
    columns: [
      {
        field: 'selected',
        headerName: 'Select',
        width: 100,
        hide: !selectionMode,
        sortable: false,
        renderHeader: () => {
          const { checked, intermediate } = selectionMode.getSelectAllState();
          return (
            <Checkbox
              checked={checked}
              intermediate={intermediate}
              onClick={(e) => {
                selectionMode.onSelectAll();
                e.stopPropagation();
              }}
              id="select_all"
            />
          );
        },
        renderCell: (params: GridCellParams) => {
          return (
            <Checkbox
              checked={selectionMode.getIsChecked(params.row.id)}
              onClick={(e) => {
                selectionMode.onCampaignSelection(params.row.id);
                e.stopPropagation();
              }}
              id="select_campaign"
            />
          );
        },
      },
      {
        field: 'externalId',
        headerName: 'ID',
        width: 100,
        sortable: true,
      },
      {
        field: 'title',
        headerName: 'Name',
        width: 375,
        sortable: true,
        renderCell: (params: GridCellParams) => {
          const { row } = params;
          return (
            <TitleWrapper>
              <CellTitleWrapper title={row.title as string} />
              {row.isLocked && <NewLockIcon dataFor={'campaign-lock-icon'} />}
            </TitleWrapper>
          );
        },
      },
      {
        field: 'type',
        headerName: 'Type',
        width: 150,
        sortable: true,
        type: 'singleSelect',
        valueOptions: Object.values(CampaignType),
        valueFormatter: (params: GridValueFormatterParams) => CampaignTypeLabel[params.value as CampaignType],
      },
      {
        field: 'schedule.campaignStart',
        headerName: 'Start',
        width: 200,
        sortable: true,
        type: 'datetime',
        valueGetter: (params: GridValueGetterParams) => params.row?.schedule?.campaignStart,
        valueFormatter: (params: GridValueFormatterParams) =>
          formatDate(params.value, dateTimeConfig, dateTimeConfig.startTimezone),
      },
      {
        field: 'schedule.campaignEnd',
        headerName: 'End',
        width: 200,
        sortable: true,
        type: 'dateTime',
        valueGetter: (params: GridValueGetterParams) => params.row?.schedule?.campaignEnd,
        valueFormatter: (params: GridValueFormatterParams) =>
          formatDate(params.value, dateTimeConfig, dateTimeConfig.endTimezone),
      },
      {
        field: 'status',
        headerName: 'Status',
        width: 180,
        sortable: true,
        renderCell: (params: GridCellParams) => {
          const { value, row }: any = params;
          const id = Math.random().toString(32);
          return (
            <StyledStatusLabel>
              <StatusLabel
                type={value === ApprovalStatus.AssociationStopped ? ApprovalStatus.Active : (value as ApprovalStatus)}
              />
              {value === ApprovalStatus.AssociationStopped && (
                <StyledLabelIcon
                  name="associationStopped"
                  title={CampaignApprovalStatusLabel[ApprovalStatus.AssociationStopped]}
                />
              )}
              {row.alert && value !== ApprovalStatus.DeploymentFailed && (
                <>
                  <AlertIconContainer data-for={`${id}-tooltip`} data-tip>
                    <StyledAlertIcon name="error" data-tip />
                  </AlertIconContainer>
                  <Tooltip
                    id={`${id}-tooltip`}
                    onHover
                    content={CampaignAlertLabel[row.alert as CampaignAlert]}
                    place="bottom"
                  />
                </>
              )}
            </StyledStatusLabel>
          );
        },
      },
      {
        field: 'redemptions',
        headerName: 'Redemptions',
        width: 150,
        sortable: true,
        renderCell: (params: GridCellParams) => {
          const { row } = params;
          return (
            <>
              {isInArray([...AvailableStatuses, ApprovalStatus.AssociationStopped], row.status) ? (
                <ButtonText
                  onClick={(e) => {
                    e.preventDefault();
                    e.stopPropagation();
                    drillDownToRedemptionsReport(row.externalId);
                  }}
                >
                  {row.redemptions}
                </ButtonText>
              ) : null}
            </>
          );
        },
      },
      ...(isPriorityList
        ? [
            {
              field: 'priority',
              headerName: 'Priority',
              width: 150,
              sortable: true,
              valueGetter: (params: GridValueGetterParams) => params.row?.priority,
              renderCell: (params: GridCellParams) => {
                const priority = params.value as PriorityLevels;
                const label = PriorityLevelLabels[priority];
                return <strong>{label}</strong>;
              },
            },
          ]
        : []),
      {
        field: '',
        headerName: '',
        width: 100,
        sortable: false,
        renderCell: (params: GridCellParams) => {
          const { id, row } = params;
          return (
            <>
              {!row.isLocked && !row?.inProgress && (
                <RoleGuard roles={[UserRole.SysAdmin, UserRole.Admin, UserRole.Creator, UserRole.Trainee]}>
                  <div id={`Actions-${id}`} style={{ display: 'none' }}>
                    <ActionsCell
                      actionTypes={
                        isInArray(
                          [
                            ApprovalStatus.Archived,
                            ApprovalStatus.Revoked,
                            ApprovalStatus.DeploymentFailed,
                            ApprovalStatus.Expired,
                          ],
                          row.status,
                        )
                          ? [ActionType.View]
                          : [ActionType.View, ActionType.Edit]
                      }
                      onActionClick={(actionType: ActionType) => onActionClick(actionType, row as CampaignProps)}
                    />
                  </div>
                </RoleGuard>
              )}
              <div id={`Actions-${id}`} style={{ display: 'none' }}>
                <ActionsCell
                  actionTypes={[ActionType.View]}
                  onActionClick={(actionType: ActionType) => onActionClick(actionType, row as CampaignProps)}
                />
              </div>
            </>
          );
        },
      },
    ],
    rows: [] as any[],
    sortingMode: 'server',
    sortingOrder: ['desc', 'asc'],
  };
};
